// Maximum head start possible
// This value is the number of yards
export const MAX_HEAD_START = 25

// Finish values for head start
//
// If exponential, it will use exponent base and exponent power to calculate, and then reverse the values
// Example (10 team): 1st = pow(base, power * 10), 2nd = pow(base, power * 9) ... 10th = pow(base, power * 1)
//
// If exponential is false, then it will use reverse standings as values.
// Example (10 team): 1st = 10, 2nd = 9, ... 10th = 1
export const SEASON_FINISH_VALUE_EXPONENTIAL = true
export const SEASON_FINISH_VALUE_EXPONENT_BASE = 1.2
export const SEASON_FINISH_VALUE_EXPONENT_POWER = 1

export const PLAYOFF_FINISH_VALUE_EXPONENTIAL = true
export const PLAYOFF_FINISH_VALUE_EXPONENT_BASE = 1.2
export const PLAYOFF_FINISH_VALUE_EXPONENT_POWER = 1

export const CONSOLATION_FINISH_VALUE_EXPONENTIAL = true
export const CONSOLATION_FINISH_VALUE_EXPONENT_BASE = 1.2
export const CONSOLATION_FINISH_VALUE_EXPONENT_POWER = 1

// If you want to scale down the consolation values, you can add a factor here
export const CONSOLATION_FINISH_VALUE_SCALE_FACTOR = 0.8

// If your league did not have a consolation bracket, you can give a value to players who did not mak playoffs
export const CONSOLATION_DEFAULT_VALUE = 0

// When combining season finish + playoff/consolation finish, you can configure how much each side matters
// Value should be between 0 and 1.
// Playoff/Consolation finish importance = 1 - SEASON_FINISH_IMPORTANCE
export const SEASON_FINISH_IMPORTANCE = 0.5

// Player speed ranges when running
// Use a yards / milliseconds scale
export const MIN_PLAYER_SPEED = 100.0 / 30000
export const MAX_PLAYER_SPEED = 100.0 / 18000

// Whether player speed changes should be changed through acceleration
// False = immediately change speed
export const PLAYER_SPEED_CHANGE_ACCELERATION = true

// How fast a player has to move to be sprinting or jogging
export const PLAYER_SPRINT_MIN_SPEED = ((MAX_PLAYER_SPEED - MIN_PLAYER_SPEED) * 2) / 3 + MIN_PLAYER_SPEED
export const PLAYER_JOG_MAX_SPEED = (MAX_PLAYER_SPEED - MIN_PLAYER_SPEED) / 3 + MIN_PLAYER_SPEED

// How often a player's speed should change, in milliseconds
export const MIN_PLAYER_SPEED_CHANGE_INTERVAL = 500
export const MAX_PLAYER_SPEED_CHANGE_INTERVAL = 1000

// How long to accelerate between speeds
export const PLAYER_SPEED_CHANGE_TIME = 500

// Chance for a player to trip while running - value is from 0 to 1
export const PLAYER_TRIP_CHANCE = 0.025

// How often to check if a player can trip, in milliseconds
export const PLAYER_TRIP_CHECK_INTERVAL = 500

// How long it takes a player to finish tripping
// This needs to always match the CSS animation duration
export const PLAYER_TRIP_ANIMATION_TIME = 1000

// How long a player should be tripped before they move again, in milliseconds
export const PLAYER_TRIP_DURATION = 1000

// Boost given to players who trip
export const PLAYER_TRIP_RECOVER_BOOST = 100.0 / 30000

// Extra time for trip recovery before calculating next speed
export const PLAYER_TRIP_RECOVER_TIME_BONUS = 1000
