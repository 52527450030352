import classNames from "classnames"
import { FC } from "react"

export type SpriteStates = "ready" | "run" | "sprint" | "jog" | "stand" | "tripped"

interface Props {
	team: string
	state: SpriteStates
}

const Sprite: FC<Props> = ({ team, state }) => {
	return <div className="team-sprite" data-team={team} data-state={state}></div>
}

export default Sprite
