import { FC } from "react"
import Hashes from "./Hashes"
import YardNumber from "./YardNumber"

interface Props {
	yard: number
}

const YardSegment: FC<Props> = ({ yard }) => {
	return (
		<div className="bg-green-700 grid grid-rows-3">
			<div className="pt-1 flex flex-col">
				<Hashes />
				<div className="flex-1 relative">{yard > 0 && yard % 10 === 0 && <YardNumber yard={yard} flipped />}</div>
			</div>
			<div className="flex flex-col">
				<Hashes />
				<div className="flex-1 flex flex-col justify-center items-center">{(yard === 0 || yard === 95) && <div className="bg-white h-10 w-0.5"></div>}</div>
				<Hashes />
			</div>
			<div className="pb-1 flex flex-col">
				<div className="flex-1 relative">{yard > 0 && yard % 10 === 0 && <YardNumber yard={yard} />}</div>
				<Hashes />
			</div>
		</div>
	)
}

export default YardSegment
