import classNames from "classnames"
import { FC } from "react"

interface Props {
	className?: string
	color?: "primary" | "red" | "gray" | "white" | "black"
	size?: "xs" | "sm" | "md" | "lg" | "xl"
}

const COLOR_STYLES = {
	primary: `border-primary-500`,
	red: `border-red-300`,
	gray: `border-gray-800`,
	white: `border-white`,
	black: `border-black`,
} as const

const SIZE_STRING_TO_VALUE = {
	xs: "w-[0.75rem] h-[0.75rem]",
	sm: "w-[1rem] h-[1rem]",
	md: "w-[1.5rem] h-[1.5rem]",
	lg: "w-[2rem] h-[2rem]",
	xl: "w-[3rem] h-[3rem]",
} as const

const Spinner: FC<Props> = ({ className, color = "primary", size = "md" }) => {
	return (
		<div
			className={classNames(`${SIZE_STRING_TO_VALUE[size]} border-2 rounded-full ${COLOR_STYLES[color]} border-l-transparent border-b-transparent animate-spin`, className)}
			style={{ animationDuration: "600ms" }}
		></div>
	)
}

export default Spinner
