import classNames from "classnames"
import { FC } from "react"

interface Props {
	className?: string
}

const Hashes: FC<Props> = ({ className }) => {
	return (
		<div className={classNames("flex justify-evenly w-full", className)}>
			<Hash />
			<Hash />
			<Hash />
			<Hash />
		</div>
	)
}

const Hash = () => <div className="bg-white h-6 w-0.5"></div>

export default Hashes
