import { useState } from "react"
import Field from "./components/Field"
import Players from "./components/Players"
import { PlayerSet } from "./utils"
import { IPlayer } from "./types"
import Results from "./components/Results"
import Prestart from "./components/Prestart"

const players = new PlayerSet(6, 4)
	.add("Jonathan", "TEN", 3, 2)
	.add("Stephen", "BAL", 2, 4)
	.add("Anthony", "BUF", 1, 1)
	.add("Michael", "TB", 7, 3, true)
	.add("Larry", "NYG", 8, 4, true)
	.add("Nick", "CHI", 5, 6)
	.add("Ryan", "MIN", 6, 5)
	.add("Kiran", "NYG", 4, 3)
	.add("Jake", "CHI")
	.add("Jeremy", "CHI")
	.done()

const App = () => {
	const [raceActive, setRaceActive] = useState(false)
	const [results, setResults] = useState<IPlayer[]>([])

	return (
		<>
			<Field>
				<Players
					players={players}
					raceActive={raceActive}
					onFinish={(players) => {
						setRaceActive(false)
						setResults(players)
					}}
				/>
			</Field>

			<Prestart show={!raceActive && !results.length} players={players} onStartRace={() => setRaceActive(true)} />

			<Results results={results} />
		</>
	)
}

export default App
