import classNames from "classnames"
import { FC } from "react"
import YardSegment from "./YardSegment"
import logo from "../../img/logo-white.png"

interface Props {
	className?: string
	children?: JSX.Element
}

const yardSegments = Array.from(new Array(20))

const Field: FC<Props> = ({ className, children }) => {
	return (
		<div className={classNames("fixed top-0 left-0 w-full h-full bg-green-700 flex p-8 flex-col justify-center", className)}>
			<div className="border-white border-8 grid grid-cols-12 aspect-field w-full">
				<div className="bg-red-500 col-span-1 border-white border-r-8 relative z-10">
					<div className="text-white -rotate-90 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap text-endzone font-bold">WE TACKLE KIDS</div>
				</div>
				<div className="col-span-10 grid grid-cols-20 gap-1 bg-white relative z-20">
					{yardSegments.map((_, i) => (
						<YardSegment key={i} yard={i * 5} />
					))}

					<img src={logo} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-15%" />

					{children}
				</div>
				<div className="bg-gray-700 col-span-1 border-white border-l-8 relative z-10">
					<div className="text-white rotate-90 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap text-endzone font-bold">WE TACKLE KIDS</div>
				</div>
			</div>
		</div>
	)
}

export default Field
