import { FC, useEffect, useState } from "react"
import { IPlayer } from "../../types"
import Player from "./Player"

interface Props {
	players: IPlayer[]
	raceActive: boolean
	onFinish: (players: IPlayer[]) => void
}

const Players: FC<Props> = ({ players, raceActive, onFinish }) => {
	const [finishOrder, setFinishOrder] = useState<IPlayer[]>([])

	useEffect(() => {
		if (finishOrder.length > 0) {
			setFinishOrder([])
		}
	}, [raceActive])

	useEffect(() => {
		if (finishOrder.length === players.length) {
			onFinish(finishOrder)
		}
	}, [finishOrder])

	return (
		<div className="absolute w-full top-10% h-80% flex flex-col justify-between">
			{players.map((player, i) => (
				<Player key={i} player={player} raceActive={raceActive} onFinish={(time) => setFinishOrder((prevFinishOrder) => [...prevFinishOrder, { ...player, time }])} />
			))}
		</div>
	)
}

export default Players
