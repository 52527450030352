import {
	CONSOLATION_DEFAULT_VALUE,
	CONSOLATION_FINISH_VALUE_EXPONENT_BASE,
	CONSOLATION_FINISH_VALUE_EXPONENT_POWER,
	CONSOLATION_FINISH_VALUE_EXPONENTIAL,
	CONSOLATION_FINISH_VALUE_SCALE_FACTOR,
	MAX_HEAD_START,
	MAX_PLAYER_SPEED,
	MAX_PLAYER_SPEED_CHANGE_INTERVAL,
	MIN_PLAYER_SPEED,
	MIN_PLAYER_SPEED_CHANGE_INTERVAL,
	PLAYOFF_FINISH_VALUE_EXPONENT_BASE,
	PLAYOFF_FINISH_VALUE_EXPONENT_POWER,
	PLAYOFF_FINISH_VALUE_EXPONENTIAL,
	SEASON_FINISH_VALUE_EXPONENT_BASE,
	SEASON_FINISH_VALUE_EXPONENT_POWER,
	SEASON_FINISH_VALUE_EXPONENTIAL,
} from "./config"
import { IPlayer } from "./types"

export class PlayerSet {
	private players: IPlayer[] = []
	private numPlayoffTeams: number = 0
	private numConsolationTeams: number = 0

	constructor(numPlayoffTeams: number, numConsolationTeams: number) {
		this.numPlayoffTeams = numPlayoffTeams
		this.numConsolationTeams = numConsolationTeams
	}

	public add(name: string, team: string, seasonFinish?: number, playoffFinish?: number, consolation?: boolean) {
		this.players.push({ name, team, seasonFinish, playoffFinish, consolation: Boolean(consolation), headStart: 0, time: 0 })
		return this
	}

	public done() {
		const numConsolationTeams = this.numConsolationTeams || this.players.length - this.numPlayoffTeams

		const seasonFinishValues = Array.from(new Array(this.players.length)).reduce<{ [key: number]: number }>((out, _, i) => {
			out[i + 1] = SEASON_FINISH_VALUE_EXPONENTIAL ? Math.pow(SEASON_FINISH_VALUE_EXPONENT_BASE, SEASON_FINISH_VALUE_EXPONENT_POWER * (this.players.length - i)) : this.players.length - 1
			return out
		}, {})
		const playoffFinishValues = Array.from(new Array(this.numPlayoffTeams)).reduce<{ [key: number]: number }>((out, _, i) => {
			out[i + 1] = PLAYOFF_FINISH_VALUE_EXPONENTIAL ? Math.pow(PLAYOFF_FINISH_VALUE_EXPONENT_BASE, PLAYOFF_FINISH_VALUE_EXPONENT_POWER * (this.numPlayoffTeams - i)) : this.numPlayoffTeams - 1
			return out
		}, {})
		const consolationFinishValues = Array.from(new Array(numConsolationTeams)).reduce<{ [key: number]: number }>((out, _, i) => {
			const pos = i + 1
			if (!this.numConsolationTeams) {
				out[pos] = CONSOLATION_DEFAULT_VALUE
			} else if (CONSOLATION_FINISH_VALUE_EXPONENTIAL) {
				out[pos] = Math.pow(CONSOLATION_FINISH_VALUE_EXPONENT_BASE, CONSOLATION_FINISH_VALUE_EXPONENT_POWER * (numConsolationTeams - i)) * CONSOLATION_FINISH_VALUE_SCALE_FACTOR
			} else {
				out[pos] = (numConsolationTeams - 1) * CONSOLATION_FINISH_VALUE_SCALE_FACTOR
			}
			return out
		}, {})

		// scale values so season = 50% and playoffs = 50%
		const totalSeason = Object.values(seasonFinishValues).reduce((sum, i) => sum + i)
		const totalPlayoffs = Object.values(playoffFinishValues).reduce((sum, i) => sum + i) + Object.values(consolationFinishValues).reduce((sum, i) => sum + i)
		const playoffScale = totalSeason / totalPlayoffs

		for (const i in playoffFinishValues) {
			playoffFinishValues[i] *= playoffScale
		}
		for (const i in consolationFinishValues) {
			consolationFinishValues[i] *= playoffScale
		}

		// if teams from last season have left and there are new players
		// calculate what finishes the teams that left had
		// then evenly distribute their values across new players
		const missingSeasonValues = Array.from(new Array(this.players.length))
			.map((_, i) => i + 1)
			.filter((x) => !this.players.some(({ seasonFinish }) => x === seasonFinish))
			.reduce((sum, i) => sum + seasonFinishValues[i], 0)
		const missingPlayoffValues = Array.from(new Array(this.numPlayoffTeams))
			.map((_, i) => i + 1)
			.filter((x) => !this.players.some(({ playoffFinish, consolation }) => x === playoffFinish && !consolation))
			.reduce((sum, i) => sum + playoffFinishValues[i], 0)
		const missingConsolationValues = Array.from(new Array(numConsolationTeams))
			.map((_, i) => i + 1)
			.filter((x) => !this.players.some(({ playoffFinish, consolation }) => x === playoffFinish && consolation))
			.reduce((sum, i) => sum + consolationFinishValues[i], 0)

		const numNewPlayers = this.players.reduce((sum, { seasonFinish }) => sum + (seasonFinish === undefined ? 1 : 0), 0)
		const newPlayerValue = numNewPlayers > 0 ? (missingSeasonValues + missingPlayoffValues + missingConsolationValues) / numNewPlayers : 0

		// limit head start to a certain yard line
		const maxValue = seasonFinishValues[1] + playoffFinishValues[1]

		const values = this.players
			.map(({ name, seasonFinish, playoffFinish, consolation }) => {
				if (seasonFinish !== undefined && playoffFinish !== undefined) {
					const value = seasonFinishValues[seasonFinish] + (consolation ? consolationFinishValues[playoffFinish] : playoffFinishValues[playoffFinish])
					//console.log(name, seasonFinish, playoffFinish, consolation, value)
					return value
				}

				return newPlayerValue
			})
			.map((value) => (value / maxValue) * MAX_HEAD_START)

		// get the smallest value, so then all teams are reduced by it so the lowest team starts at 0
		const minValue = Math.min(...values)

		this.players.forEach((player, i) => {
			player.headStart = Math.round(values[i] - minValue)
		})

		this.players.sort((a, b) => (a.headStart > b.headStart ? -1 : 1))

		return this.players
	}
}

export const randomRange = (min: number, max: number) => Math.random() * (max - min) + min
export const randomSpeed = () => randomRange(MIN_PLAYER_SPEED, MAX_PLAYER_SPEED)
export const randomChangeInterval = () => randomRange(MIN_PLAYER_SPEED_CHANGE_INTERVAL, MAX_PLAYER_SPEED_CHANGE_INTERVAL)
