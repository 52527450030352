import classNames from "classnames"
import { ButtonHTMLAttributes, FC, ReactNode } from "react"
import Spinner from "./Spinner"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	children?: ReactNode
	color?: "primary" | "red" | "gray"
	isLoading?: boolean
	leftIcon?: ReactNode
	rightIcon?: ReactNode
	size?: "sm" | "md" | "lg" | "xl"
	variant?: "filled" | "ghost" | "outline"
}

const COLOR_STYLES = {
	primary: {
		filled: "text-white bg-primary-600 hover:bg-primary-500",
		ghost: "text-primary-500 bg-transparent hover:bg-primary-400/10",
		outline: "text-primary-500 border-1 border-primary-500 bg-transparent hover:bg-primary-400/10",
	},
	red: {
		filled: "text-white bg-red-500 hover:bg-red-800",
		ghost: "text-red-500 bg-transparent hover:bg-red-400/10",
		outline: "text-red-500 border-1 border-red-500 bg-transparent hover:bg-red-400/10",
	},
	gray: {
		filled: "text-white bg-gray-700 hover:bg-gray-600",
		ghost: "text-gray-500 bg-transparent hover:bg-gray-700/10",
		outline: "text-gray-500 border-1 border-gray-700 bg-transparent hover:bg-gray-700/10",
	},
} as const

const SPINNER_COLOR = {
	primary: {
		filled: "gray",
		ghost: "primary",
		outline: "primary",
	},
	red: {
		filled: "gray",
		ghost: "red",
		outline: "red",
	},
	gray: {
		filled: "white",
		ghost: "gray",
		outline: "gray",
	},
} as const

const SIZE_STYLES = {
	sm: "text-sm px-2 py-1",
	md: "px-3 py-2",
	lg: "text-lg px-5 py-3",
	xl: "text-xl px-7 py-4",
}

const Button: FC<Props> = ({ children, className, color = "primary", disabled, isLoading, leftIcon, rightIcon, size = "md", variant = "filled", ...props }) => {
	props.type = props.type ?? "button"

	return (
		<button
			className={classNames(
				COLOR_STYLES[color][variant],
				SIZE_STYLES[size],
				isLoading ? "cursor-progress" : disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
				"block rounded-lg font-bold relative",
				className
			)}
			disabled={disabled || isLoading}
			{...props}
		>
			{isLoading && (
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					<Spinner color={SPINNER_COLOR[color][variant]} />
				</div>
			)}
			<span className={classNames("flex gap-3 items-center justify-center", { invisible: isLoading })}>
				{leftIcon}
				<span>{children}</span>
				{rightIcon}
			</span>
		</button>
	)
}

export default Button
