import classNames from "classnames"
import { FC } from "react"
import Icon from "../Icon"

interface Props {
	yard: number
	flipped?: boolean
}

const YardNumber: FC<Props> = ({ yard, flipped }) => {
	const actualYard = yard >= 50 ? 100 - yard : yard

	return (
		<div className="absolute top-1/2 left-0">
			<div className="-translate-x-50% -translate-y-50% -ml-0.5">
				<div className={classNames("relative grid grid-cols-2 text-5xl gap-2", flipped && "rotate-180")}>
					<div className="text-right font-number text-white">{actualYard / 10}</div>
					<div className="font-number text-white">0</div>
					{yard !== 50 && (
						<div className={classNames("absolute top-[4px] text-white", (yard < 50 ? !flipped : flipped) ? "right-100% translate-x-25%" : "left-100% -translate-x-25%")}>
							<Icon size="lg" icon={(yard < 50 ? !flipped : flipped) ? "arrow_left" : "arrow_right"} />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default YardNumber
