import classNames from "classnames"
import { FC, MouseEventHandler, ReactNode, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import Button from "./Button"
import Icon from "./Icon"

interface Props {
	isOpen: boolean
	showCloseButton?: boolean
	showOverlay?: boolean
	closeOnOverlayClick?: boolean
	onClose?: () => void
	header?: ReactNode
	content?: ReactNode
	footer?: ReactNode
	thin?: boolean
	size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "full"
}

const SIZE_TO_CLASSNAME = {
	xs: "max-w-xs",
	sm: "max-w-sm",
	md: "max-w-md",
	lg: "max-w-lg",
	xl: "max-w-xl",
	"2xl": "max-w-2xl",
	"3xl": "max-w-3xl",
	"4xl": "max-w-4xl",
	"5xl": "max-w-5xl",
	"6xl": "max-w-6xl",
	full: "max-w-full",
} as const

const SIZE_ORDER = ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl"] as const

const Modal: FC<Props> = ({ isOpen, showCloseButton, showOverlay = true, closeOnOverlayClick, onClose, header, content, footer, size = "md", thin = false }) => {
	const [adjustedSize, setAdjustedSize] = useState(size)
	const prevOpen = useRef(isOpen)
	const modalRef = useRef<HTMLDivElement>(null)

	const readjustSize = () => {
		if (adjustedSize !== "full" && modalRef.current && modalRef.current.offsetTop + modalRef.current.clientHeight > window.innerHeight) {
			const currentSizeIndex = SIZE_ORDER.indexOf(adjustedSize)
			if (currentSizeIndex > 0) {
				setAdjustedSize(SIZE_ORDER[currentSizeIndex - 1])
			}
		}
	}

	useEffect(() => {
		document.body.classList.toggle("overflow-hidden", isOpen)

		const done = () => {
			prevOpen.current = isOpen
		}

		if (size === "full" || adjustedSize === "full") {
			return done()
		}

		if (isOpen === prevOpen.current || (isOpen && size === adjustedSize)) {
			readjustSize()
		} else if (isOpen) {
			// just now opening, reset size
			setAdjustedSize(size)
		}

		return done()
	}, [isOpen, size, adjustedSize])

	const handleCloseClick: MouseEventHandler = (event) => {
		event.preventDefault()
		onClose?.()
	}

	const handleOverlayClick: MouseEventHandler = (event) => {
		event.preventDefault()
		if (closeOnOverlayClick) {
			onClose?.()
		}
	}

	const render = (
		<div className={classNames({ hidden: !isOpen })}>
			<div className={classNames("fixed top-0 left-0 w-[100vw] h-[100vh] z-[90] flex justify-center items-start", showOverlay && "bg-black/20")} onClick={handleOverlayClick}></div>
			<div
				ref={modalRef}
				className={classNames(
					`fixed top-0 w-full z-[100] ${SIZE_TO_CLASSNAME[adjustedSize]} bg-white`,
					adjustedSize === "full" ? "left-0 w-[100vw] h-[100vh]" : "my-16 left-1/2 -translate-x-1/2 shadow rounded-md"
				)}
			>
				{showCloseButton && (
					<div className="absolute top-2 right-3">
						<Button color="gray" variant="ghost" onClick={handleCloseClick}>
							<Icon icon="close" />
						</Button>
					</div>
				)}
				<div className="flex flex-col">
					{Boolean(header || showCloseButton) && <div className="px-6 py-4 text-xl font-bold">{header}</div>}
					{content && <div className={classNames("flex-1", !thin && "py-4 px-6")}>{content}</div>}
					{footer && <div className="px-6 py-4 flex items-center justify-end">{footer}</div>}
				</div>
			</div>
		</div>
	)

	return ReactDOM.createPortal(render, document.getElementById("modal-portal") as HTMLElement)
}

export default Modal
