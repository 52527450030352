import { FC, MouseEvent, useState } from "react"
import Modal from "./Modal"
import Button from "./Button"
import { IPlayer } from "../types"
import classNames from "classnames"
import Sprite from "./Sprite"

interface Props {
	show: boolean
	players: IPlayer[]
	onStartRace: () => void
}

const Prestart: FC<Props> = ({ show, players, onStartRace }) => {
	const [counter, setCounter] = useState(0)

	const handleStartRace = (event: MouseEvent) => {
		event.preventDefault()

		setCounter(3)
		setTimeout(() => setCounter(2), 1000)
		setTimeout(() => setCounter(1), 2000)
		setTimeout(() => {
			setCounter(0)
			onStartRace()
		}, 3000)
	}

	return (
		<Modal
			isOpen={show}
			size="3xl"
			showOverlay={false}
			content={
				<>
					<table className="w-full text-lg">
						<thead>
							<tr>
								<th className="text-left px-4 py-1">Player</th>
								<th className="text-left px-4 py-1">Season Finish</th>
								<th className="text-left px-4 py-1">Playoff Finish</th>
								<th className="text-left px-4 py-1">Head Start</th>
							</tr>
						</thead>
						<tbody>
							{players.map((player, i) => (
								<tr className={classNames(i % 2 === 0 && "bg-gray-50")}>
									<td className="px-4 py-1">
										<div className="flex gap-2">
											<Sprite team={player.team} state="jog" />
											<div>{player.name}</div>
										</div>
									</td>
									<td className="px-4 py-1">{player.seasonFinish ?? <span className="text-sm">(new)*</span>}</td>
									<td className="px-4 py-1">
										<div className="flex gap-2 items-center">
											{!player.playoffFinish && <span className="text-sm">(new)*</span>}
											{player.playoffFinish && <span>{player.playoffFinish}</span>}
											{player.consolation && <span className="text-sm">(consolation)</span>}
										</div>
									</td>
									<td className="px-4 py-1">{Math.floor(player.headStart)}</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							{players.some((player) => !player.seasonFinish) && (
								<tr>
									<td colSpan={4} className="px-4 py-1 text-sm">
										<div className="flex gap-2">
											<span>*</span>
											<span>
												New players get the average of values for the players that left the league.
												<br />
												If no players left the league, then new players start at 0.
											</span>
										</div>
									</td>
								</tr>
							)}
						</tfoot>
					</table>

					<div className="flex justify-center mt-8">
						<Button color="red" size="xl" className="w-full" onClick={handleStartRace}>
							{counter === 0 ? "Start Race" : counter}
						</Button>
					</div>
				</>
			}
		/>
	)
}

export default Prestart
