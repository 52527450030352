import classNames from "classnames"
import { CSSProperties, FC } from "react"

interface Props {
	className?: string
	icon: string
	filled?: boolean
	size?: "xs" | "sm" | "md" | "lg"
	weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700
	grade?: -25 | 0 | 200
	style?: CSSProperties
}

const SIZE_STRING_TO_NUMBER = {
	xs: 16,
	sm: 24,
	md: 32,
	lg: 40,
}

const Icon: FC<Props> = ({ className, icon, filled, size = "sm", weight = 400, grade = 0, style }) => {
	return (
		<span
			className={classNames("material-symbols-outlined", className)}
			style={{
				fontSize: `${SIZE_STRING_TO_NUMBER[size] / 16}rem`,
				fontVariationSettings: `'FILL' ${filled ? 1 : 0}, 'wght' ${weight}, 'GRAD' ${grade}, 'opsz' ${SIZE_STRING_TO_NUMBER[size]}`,
				...style,
			}}
		>
			{icon}
		</span>
	)
}

export default Icon
