import { FC, useEffect, useRef, useState } from "react"
import { IPlayer } from "../../types"
import {
	PLAYER_JOG_MAX_SPEED,
	PLAYER_SPEED_CHANGE_ACCELERATION,
	PLAYER_SPEED_CHANGE_TIME,
	PLAYER_SPRINT_MIN_SPEED,
	PLAYER_TRIP_ANIMATION_TIME,
	PLAYER_TRIP_CHANCE,
	PLAYER_TRIP_CHECK_INTERVAL,
	PLAYER_TRIP_DURATION,
	PLAYER_TRIP_RECOVER_BOOST,
	PLAYER_TRIP_RECOVER_TIME_BONUS,
} from "../../config"
import Sprite, { SpriteStates } from "../Sprite"
import { randomChangeInterval, randomSpeed } from "../../utils"

interface Props {
	player: IPlayer
	raceActive: boolean
	onFinish: (time: number) => void
}

// ease in out sine
const accelerateEasing = (progress: number) => -(Math.cos(Math.PI * progress) - 1) / 2

interface PlayerState {
	animationFrame: number
	lastAnimationFrameTime: number
	startTime: number
	currentSpeed: number
	tripped: boolean

	speedBeforeAccelerate: number
	speedAfterAccelerate: number
	accelerateStartTime: number
	accelerateEndTime: number

	nextSpeedChangeTime: number
	nextTripChanceTime: number

	finished: boolean
}

const Player: FC<Props> = ({ player, raceActive, onFinish }) => {
	const [left, setLeft] = useState(player.headStart - 1)
	const [state, setState] = useState<SpriteStates>("ready")

	const playerStateRef = useRef<PlayerState>({
		animationFrame: 0,
		lastAnimationFrameTime: 0,
		startTime: 0,
		currentSpeed: 0,
		tripped: false,

		speedBeforeAccelerate: 0,
		speedAfterAccelerate: 0,
		accelerateStartTime: 0,
		accelerateEndTime: 0,

		nextSpeedChangeTime: 0,
		nextTripChanceTime: 0,

		finished: false,
	})

	const animate = (time: number) => {
		const playerState = playerStateRef.current

		if (playerState.finished) return

		if (playerState.nextSpeedChangeTime === 0) {
			playerState.nextSpeedChangeTime = time + randomChangeInterval() + PLAYER_SPEED_CHANGE_TIME
			playerState.tripped = false

			if (PLAYER_SPEED_CHANGE_ACCELERATION) {
				playerState.accelerateStartTime = time
				playerState.accelerateEndTime = time + PLAYER_SPEED_CHANGE_TIME
			} else {
				playerState.currentSpeed = playerState.speedAfterAccelerate
			}
		} else if (time >= playerState.nextSpeedChangeTime) {
			// check if player was tripped
			const tripBoost = playerState.tripped ? PLAYER_TRIP_RECOVER_BOOST : 0

			playerState.nextSpeedChangeTime = time + randomChangeInterval() + PLAYER_SPEED_CHANGE_TIME + (playerState.tripped ? PLAYER_TRIP_RECOVER_TIME_BONUS : 0)
			playerState.speedBeforeAccelerate = playerState.currentSpeed
			playerState.speedAfterAccelerate = randomSpeed() + tripBoost
			playerState.tripped = false

			if (PLAYER_SPEED_CHANGE_ACCELERATION) {
				playerState.accelerateStartTime = time
				playerState.accelerateEndTime = time + PLAYER_SPEED_CHANGE_TIME
			} else {
				playerState.currentSpeed = playerState.speedAfterAccelerate
			}
		}

		if (playerState.accelerateStartTime > 0 && playerState.accelerateEndTime > 0) {
			const speedRange = playerState.speedAfterAccelerate - playerState.speedBeforeAccelerate
			const minSpeed = playerState.speedBeforeAccelerate
			const timeRange = playerState.accelerateEndTime - playerState.accelerateStartTime
			const elapsedTime = Math.min(time, playerState.accelerateEndTime) - playerState.accelerateStartTime

			playerState.currentSpeed = accelerateEasing(elapsedTime / timeRange) * speedRange + minSpeed

			if (time >= playerState.accelerateEndTime) {
				playerState.accelerateStartTime = 0
				playerState.accelerateEndTime = 0
			}
		}

		if (playerState.currentSpeed > 0 && time >= playerState.nextTripChanceTime) {
			if (Math.random() < PLAYER_TRIP_CHANCE) {
				playerState.nextSpeedChangeTime = time + PLAYER_TRIP_DURATION + PLAYER_TRIP_ANIMATION_TIME
				playerState.speedBeforeAccelerate = playerState.currentSpeed
				playerState.speedAfterAccelerate = 0
				playerState.tripped = true

				if (PLAYER_SPEED_CHANGE_ACCELERATION) {
					playerState.accelerateStartTime = time
					playerState.accelerateEndTime = time + PLAYER_TRIP_ANIMATION_TIME
				} else {
					playerState.currentSpeed = 0
				}

				playerState.nextTripChanceTime = Math.max(playerState.nextSpeedChangeTime, time + PLAYER_TRIP_CHECK_INTERVAL)
			} else {
				playerState.nextTripChanceTime = time + PLAYER_TRIP_CHECK_INTERVAL
			}
		}

		if (playerState.tripped) {
			setState("tripped")
		} else if (playerState.currentSpeed >= PLAYER_SPRINT_MIN_SPEED) {
			setState("sprint")
		} else if (playerState.currentSpeed <= PLAYER_JOG_MAX_SPEED) {
			setState("jog")
		} else {
			setState("run")
		}

		if (playerState.lastAnimationFrameTime > 0) {
			const deltaTime = time - playerState.lastAnimationFrameTime

			setLeft((prevLeft) => Math.min(101, prevLeft + deltaTime * playerState.currentSpeed))
		}

		playerState.lastAnimationFrameTime = time
		playerState.animationFrame = requestAnimationFrame(animate)
	}

	useEffect(() => {
		if (!raceActive) return

		setLeft(player.headStart)
		setState("ready")

		const playerState = playerStateRef.current
		playerState.currentSpeed = 0
		playerState.lastAnimationFrameTime = 0
		playerState.startTime = new Date().getTime()
		playerState.tripped = false

		playerState.speedBeforeAccelerate = 0
		playerState.speedAfterAccelerate = randomSpeed()
		playerState.accelerateStartTime = 0
		playerState.accelerateEndTime = 0

		playerState.nextSpeedChangeTime = 0
		playerState.nextTripChanceTime = 0

		playerState.finished = false

		playerState.animationFrame = requestAnimationFrame(animate)
		return () => cancelAnimationFrame(playerState.animationFrame)
	}, [raceActive])

	useEffect(() => {
		if (left >= 100 && !playerStateRef.current.finished) {
			playerStateRef.current.finished = true
			setState(Math.random() < 0.1 ? "tripped" : "stand")
			onFinish(new Date().getTime() - playerStateRef.current.startTime)
		}
	}, [left])

	return (
		<div className="relative w-full">
			<div className="absolute" style={{ left: `${left}%` }}>
				<div className="absolute top-1/2 right-100% -translate-y-50% -translate-x-2 px-2 text-white bg-black/65 rounded-lg">{player.name}</div>
				<Sprite team={player.team} state={state} />
			</div>
		</div>
	)
}

export default Player
