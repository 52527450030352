import { FC, useEffect, useState } from "react"
import { IPlayer } from "../types"
import Modal from "./Modal"
import classNames from "classnames"
import Sprite from "./Sprite"
import Button from "./Button"

interface Props {
	results: IPlayer[]
}

const Results: FC<Props> = ({ results }) => {
	const [draftPositions, setDraftPositions] = useState<number[]>([])

	useEffect(() => {
		setDraftPositions(results.map(() => 0))
	}, [results.length])

	return (
		<Modal
			isOpen={results.length > 0}
			size="6xl"
			showOverlay={false}
			header="Lottery Results"
			content={
				<table className="w-full text-lg">
					<thead>
						<tr>
							<th className="text-left px-4 py-1">#</th>
							<th className="text-left px-4 py-1">Player</th>
							<th className="text-left px-4 py-1">Start</th>
							<th className="text-left px-4 py-1">Time</th>
							<th className="text-left px-4 py-1">Draft Position</th>
						</tr>
					</thead>
					<tbody>
						{results.map((player, i) => (
							<tr className={classNames(draftPositions[i] === 0 && (i === 0 || draftPositions[i - 1] > 0) ? "bg-green-600 text-white" : i % 2 === 0 ? "bg-gray-50" : "")}>
								<td className="px-4 py-1">{i + 1}</td>
								<td className="px-4 py-1">
									<div className="flex gap-2">
										<Sprite team={player.team} state="jog" />
										<div>{player.name}</div>
									</div>
								</td>
								<td className="px-4 py-1">{Math.floor(player.headStart)}</td>
								<td className="px-4 py-1">{i === 0 ? (player.time / 1000).toFixed(3) : <>+{((player.time - results[0].time) / 1000).toFixed(3)}</>}</td>
								<td className="px-4 py-1">
									<div className="grid gap-1" style={{ gridTemplateColumns: `repeat(${results.length}, 1fr)` }}>
										{results.map((_, pos) => (
											<Button
												color={draftPositions[i] === pos + 1 ? "primary" : "gray"}
												size="sm"
												variant={
													(i > 0 && draftPositions[i - 1] === 0) ||
													(draftPositions[i] > 0 && draftPositions[i] !== pos + 1) ||
													draftPositions.some((x, j) => x === pos + 1 && j !== i)
														? "ghost"
														: "filled"
												}
												disabled={
													(i > 0 && draftPositions[i - 1] === 0) ||
													(draftPositions[i] > 0 && draftPositions[i] !== pos + 1) ||
													draftPositions.some((x, j) => x === pos + 1 && j !== i)
												}
												onClick={(event) => {
													event.preventDefault()
													draftPositions[i] = pos + 1
													setDraftPositions([...draftPositions])
												}}
											>
												{pos + 1}
											</Button>
										))}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			}
		/>
	)
}

export default Results
